import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
export const AddDocument = ({ onClick, text }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        position: "relative",
        height: "38.8px",
        minWidth: "173px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: " 4px 14px 4px 14px",
        gap: "8px",
        borderRadius: "2px",
        border: "1px solid #D9D9D9",
        color: "black",
        boxShadow: "2px #0000000B",
        textTransform: "none",
        cursor: "pointer",
        transition: "background-color 0.3s ease"
      }}
    //   onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#004BB4"}
    //   onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#002D72"}
    >
        {text}
    </Button>
  );
};
export default AddDocument;