import { FORMS_GET_FAIL, FORMS_GET_PENDING, FORMS_GET_SUCCESS, UPLOAD_FORM_FAIL, UPLOAD_FORM_PENDING, UPLOAD_FORM_SUCCESS } from "../../actions/types";
  
  const initialState = {
    forms: [],
  };
  
  const forms_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    
    switch (type) {
      case FORMS_GET_SUCCESS:
        return {
          ...state,
          forms: payload.forms,
        };
      case FORMS_GET_PENDING:
        return {
          ...state,
          forms: payload.forms,
        };
      case FORMS_GET_FAIL:
        return {
          ...state,
          forms: [],
        };

        case UPLOAD_FORM_SUCCESS:
            return {
              ...state,
              uploadForm : [...state.uploadForm, payload.uploadForm],
            };
          case UPLOAD_FORM_PENDING:
            return {
              ...state,
              uploadForm: payload.uploadForm,
            };
          case UPLOAD_FORM_FAIL:
            return {
              ...state,
              uploadForm: [],
            };


  
       
      default:
        return state;
    }
  };
  export default forms_reducer;
  