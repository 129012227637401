import React, { useEffect, useRef } from 'react';
import '../gMap/Gmap.css'
 
const GMap = (props) => {
    const googleMapRef = useRef(null);
    let googleMap = null;
    var markerList = props.markerList ? props.markerList : []
   
    // list of icons
    const iconList = {
      icon1: 'https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/150/google-maps-512.png',
      icon2: 'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png',
      icon3: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Ball-Right-Azure.png',
      icon4: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png'
      
    }
   
    // list of the marker object along with icon
    useEffect(() => {
      googleMap = initGoogleMap();
      var bounds = new window.google.maps.LatLngBounds();
      markerList.map(x => {
        const marker = createMarker(x);
        bounds.extend(marker.position);
      });
      googleMap.fitBounds(bounds);
      google.maps.event.addListenerOnce(googleMap, 'idle', () => {
        googleMap.setZoom(17); 
    });
    }, []);
   
   
    // initialize the google map
    const initGoogleMap = () => {
      return new window.google.maps.Map(googleMapRef.current, {});
  };
   
    // create marker on google map
    const createMarker = (markerObj) => new window.google.maps.Marker({
      position: { lat: markerObj.lat, lng: markerObj.lng },
      map: googleMap,
      title: markerObj.title,
    //   label: {text:markerObj.title,
    //     fontSize: "15px",
    //     fontWeight: "bold",
    //     color:"#D22B2B",
    //     position: "absolute",
    //     },
        icon: {
        url: markerObj.icon,
        labelOrigin: new window.google.maps.Point(30, 60),
        // set marker width and height
        scaledSize: new window.google.maps.Size(50, 50)
      }
    });
 
  return <div
    ref={googleMapRef}
    style={{ width: '100%', height: '100%' }}
  />
}
 
export default GMap;