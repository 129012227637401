import React, { useState, useEffect } from "react";
import "../gMap/Gmap.css";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../Constatns";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const MyMap = ({ markerList, mapCenter, mapZoom }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = useState(null);
  const [selectedMarkers, setSelectedMarkers] = useState(new Set());

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  useEffect(() => {
    if (map && markerList.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      selectedMarkers.forEach((markerId) => {
        const marker = markerList.find((m) => m.id === markerId);
        if (marker) {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        }
      });

      if (selectedMarkers.size > 0) {
        map.fitBounds(bounds);
      } else {
        const defaultBounds = new window.google.maps.LatLngBounds();
        markerList.forEach((marker) => {
          defaultBounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        });
        map.fitBounds(defaultBounds);
      }
    }
  }, [map, selectedMarkers, markerList]);

  const handleMarkerClick = (marker) => {
    const newSelectedMarkers = new Set(selectedMarkers);
    if (newSelectedMarkers.has(marker.id)) {
      newSelectedMarkers.delete(marker.id);
    } else {
      newSelectedMarkers.add(marker.id); 
    }
    setSelectedMarkers(newSelectedMarkers);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={mapZoom}
      onLoad={onLoad}
    >
      {markerList.map((marker) => (
        <Marker
          key={marker.id}
          position={{ lat: marker.lat, lng: marker.lng }}
          icon={{
            url:
              "data:image/svg+xml;charset=UTF-8," +
              encodeURIComponent(`
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#002D72" width="24px" height="24px">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 4.73 5.26 11.74 6.1 12.82.45.55 1.35.55 1.8 0C13.74 20.74 19 13.73 19 9c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z"/>
              </svg>
            `),
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          label={{
            text: marker.title,
            color: "#002D72",
            fontSize: "14px",
            fontWeight: "bold",
            marginLeft: "15px",
          }}
          onClick={() => handleMarkerClick(marker)}
        />
      ))}

      {Array.from(selectedMarkers).map((markerId) => {
        const marker = markerList.find((m) => m.id === markerId);
        return (
          <InfoWindow
            key={markerId}
            position={{ lat: marker.lat, lng: marker.lng }}
            onCloseClick={() => handleMarkerClick(marker)}
          >
            <div className="info-window">
              <h4>{marker.title}</h4>
           
              <p>
                <span className="info-label">Designation:</span>
                <span className="info-value"> {marker.designation}</span>
              </p>
             
              <p>
                <span className="info-label">Last Updated:</span>
                <span className="info-value"> {marker.time_stamp}</span>
              </p>
              <div className="divider"></div>
              <p>
                <span className="info-label">Reports To:</span>
                <span className="info-value"> {marker.reportsTo}</span>
              </p>
            </div>
          </InfoWindow>
        );
      })}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MyMap;
