import React, { useEffect } from "react";
import "../Tasks.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { getTaskCities, getTaskDoctors, getTaskPracticeLocations, } from "../../../actions/userActions/tasks/taskAction";
import { addTasks } from "../../../actions/userActions/tasks/taskAction";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {  getTaskTypes } from '../../../actions/userActions/tasks/taskAction';
import { getEmployeeSubOrdinates } from '../../../actions/userActions/employeeAction';
import { getTaskProducts, getTaskSubTasks } from '../../../actions/userActions/tasks/taskAction';
import dayjs from 'dayjs';
import moment from "moment";
import { CancelButton } from "../../subComponents/buttons/CancelButton";
import { SaveButton } from "../../subComponents/buttons/SaveButton";
import HrComponent from "../../reuse_components/HrComponent";
import { getTaskPriorities } from "../../../actions/userActions/taskPriorityAction";
import { SIZE_OF_PAGE } from "../../../actions/types";
import { getSubTasks } from "../../../actions/userActions/subTaskAction";

function AddTasks(props) {
  const history = useHistory();
  const currentDate = moment();
  const location = useLocation();
  const dispatch = useDispatch();
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const user = JSON.parse(localStorage.getItem("user"));
  const preselectedCity = user.result[0] !== undefined ? user.result[0].user.cityId : false;
  const [doctorCity, setDoctorCity] = useState(preselectedCity);
  const [locationCity, setLocationCity] = useState(preselectedCity);
  const [formErrors, setFormErrors] = useState({});
  const [isAssignedToMe, setIsAssignedToMe] = useState(false);
  const [assignedToName, setAssignedToName] = useState("");
  const addTask = useSelector((state) => state.tasks_reducer.addTask);

  const employeeId = user.result[0] !== undefined ? user.result[0].user.id : false;
  const initialValues = {
    employeeId: "",
    title: "",
    description: "",
    doctorId: "",
    practiceLocationId: "",
    assignedTo: "",
    priority: "",
    type: location.taskType,
    products: [],
    subTasks: [],
    visitDate: moment(currentDate).format("YYYY-MM-DD"),
    createdByName: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [query, setQuery] = useState('');

  const [selectedValues, setSelectedValues] = useState(initialValues);
  const allProductBody = {
    "filter": {
      "textSearch": ""
    },
    "pageNumber": -1,
    "pageSize": 0
  }
  useEffect(() => {
    setFormValues({
      ...formValues,
      employeeId: user.result[0]?.user.id,
      createdByName: `${user.result[0]?.user.firstName} ${user.result[0]?.user.lastName}`,
    });
  }, []);

  useEffect(() => {
    const pageNumber = -1
    dispatch(getTaskCities()).then(() => {
      dispatch(getSubTasks(pageNumber));
      dispatch(getTaskProducts(allProductBody));
      dispatch(getTaskPriorities(pageNumber, SIZE_OF_PAGE, query));
      dispatch(getEmployeeSubOrdinates(employeeId));
    });
  }, []);

  useEffect(() => {
    if (locationCity) {
      dispatch(getTaskPracticeLocations(locationCity));
    }
  }, [locationCity]);

  useEffect(() => {
    if (doctorCity) {
      const allDoctorsBody = {
        pageNumber: -1,
        pageSize: 0,
        textSearch: "",
        cityId: doctorCity,
      };
      dispatch(getTaskDoctors(allDoctorsBody));
    }
  }, [doctorCity]);

  const doctors = useSelector(state => state.tasks_reducer.taskDoctors);
  const locations = useSelector(state => state.tasks_reducer.taskPracticeLocations);
  const employees = useSelector(state => state.employees_reducer.employeeSubordinates);
  const products = useSelector(state => state.tasks_reducer.taskProducts);
  const subTasks = useSelector((state) => state.sub_tasks_reducer.subTasks);

  const subTasksData = subTasks && subTasks.result;
  
  
  const priorities = useSelector(state => state.tasks_reducer.taskPriorities);
  
  const prioritiesData = priorities && priorities.result;
  
  const cities = useSelector(
    (state) => state.tasks_reducer?.taskCities
  );
  const handleDoctorCityChange = (event) => {
    setDoctorCity(event.target.value);
  };
  const handleLocationCityChange = (event) => {
    setLocationCity(event.target.value);
  };
  const handleDoctorChange = (selectedDoctorId) => {
    const selectedDoctor = doctors.find(doctor => doctor.id === selectedDoctorId);
    setSelectedValues(prevValues => ({
      ...prevValues,
      doctorId: selectedDoctorId,
      title: selectedDoctor ? selectedDoctor.name : ''
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      doctorId: selectedDoctorId,
      title: selectedDoctor ? selectedDoctor.name : ''
    }));
  };
  const handleLocationChange = (selectedLocationId) => {
    const selectedLocation = locations.find(location => location.id === selectedLocationId);
    setSelectedValues(prevValues => ({
      ...prevValues,
      practiceLocationId: selectedLocationId,
      description: selectedLocation ? selectedLocation.address : ''
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      practiceLocationId: selectedLocationId,
      description: selectedLocation ? selectedLocation.address : ''
    }));
  };
  const handlePriorityChange = (selectedPriorityId) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      priority: selectedPriorityId,
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      priority: selectedPriorityId,
    }));
  };
  const handleAssignToChange = (selectedAssignToId) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      assignedTo: selectedAssignToId,
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      assignedTo: selectedAssignToId,
    }));
  };
  const handleProductChange = (event, selectedProducts) => {
    const selectedProductIds = selectedProducts.map(product => product.id);
    setSelectedValues(prevValues => ({
      ...prevValues,
      products: selectedProductIds,
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      products: selectedProductIds,
    }));
  };
  const handleSubTasksChange = (event, selectedSubTasks) => {
    const selectedSubTaskIds = selectedSubTasks.map(subTask => subTask.id);
    setSelectedValues(prevValues => ({
      ...prevValues,
      subTasks: selectedSubTaskIds,
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      subTasks: selectedSubTaskIds,
    }));
  };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelectedValues(prevValues => ({
      ...prevValues,
      visitDate: formattedDate,
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      visitDate: formattedDate,
    }));
  };
  const validate = (values) => {
    const errors = {};
    if (!values.doctorId) {
      errors.doctorId = <p className="text-red">Doctor is required!</p>;
    }
    if (!values.practiceLocationId) {
      errors.practiceLocationId = <p className="text-red">Location is required! </p>;
    }

    if (!values.assignedTo) {
      errors.assignedTo = <p className="text-red">Assigned To is required!</p>;
    }
    if (!values.priority) {
      errors.priority = <p className="text-red">Priority is required!</p>;
    }
    if (values.products.length === 0) {
      errors.products = <p className="text-red">Products is required!</p>;
    }
    if (values.subTasks.length === 0) {
      errors.subTasks = <p className="text-red">Sub Tasks is required!</p>;
    }
    if (!values.visitDate) {
      errors.visitDate = <p className="text-red">Visit Date is required!</p>;
    }


    return errors;
  };

  const addTaskHandle = async (e) => {
    e.preventDefault();
    const updatedFormValues = {
        ...formValues,
        assignedTo: isAssignedToMe ? employeeId : formValues.assignedTo, 
    };
    const errors = validate(updatedFormValues);
    if (Object.keys(errors).length === 0) {
        dispatch(addTasks(updatedFormValues));
    } else {
        setFormErrors(errors); 
    }
};

  const handleGoBack = () => {
    history.goBack();
  };

  const handleChange = () => {
    if (!isAssignedToMe) {
      setAssignedToName(""); 
      setSelectedValues({ ...selectedValues, assignedTo: "" }); 
    } else {
      setAssignedToName(employeeId); 
      setSelectedValues({ ...selectedValues, assignedTo: employeeId }); 
    }
    setIsAssignedToMe(!isAssignedToMe); 
  };
  
  return (
    <>
      {(addTask && addTask !== "isLoading" ) && <Redirect to="/admin/tasks" />}
      {!permissions.includes("CanAddTask") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdateTask") && <Redirect to="/admin" />}
      {!location.id && <Redirect to={"/admin/AddTasks"} />}
      <div>
        <div className="row">
          <div
            className="col-md-12"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <i onClick={history.goBack}>
              <svg
                style={{
                  cursor: "pointer", color: "#000000"
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 11.5H7.83L13.42 5.91L12 4.5L4 12.5L12 20.5L13.41 19.09L7.83 13.5H20V11.5Z"
                  fill="#505157"
                />
              </svg>
            </i>{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2 className="d-inline-block" style={{
                fontFamily: "Roboto",
                fontSize: "28px",
                fontWeight: "500",
                lineHeight: "33px",
                textAlign: "left",
                color: "#272727"
              }}>
                Add Task
              </h2>
            </div>

          </div>
          <div>
            <HrComponent />
          </div>
        </div>
        <div style={{
          padding: "24px 0px",
          // gap: "24px"
        }}>
          <div className="row" style={{ gap: "16px" }}>
            <div className="col-12" >
              <h5 style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                textAlign: "left",
                color: "#000000"
              }}>
                Doctor
              </h5>
            </div>
            <div className="col-12 d-flex" style={{ gap: "8px" }}>
              
              <div className="col-6 pl-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City *</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={doctorCity}
                    label="City *"
                    onChange={handleDoctorCityChange}
                    MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                  >
                    {cities === "isLoading" && (
                      <MenuItem disabled>
                        Loading...
                      </MenuItem>
                    )}

                    {cities !== "isLoading" && cities.length === 0 && (
                      <MenuItem disabled>
                        No data
                      </MenuItem>
                    )}

                    {cities !== "isLoading" && cities.length !== 0 && cities[0].cities
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(city => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>

              <div className="col-6 pl-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Doctor *</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Doctor *"
                    value={selectedValues.doctorId}
                    onChange={(event) => handleDoctorChange(event.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                        },
                      },
                    }}
                  >
                    {doctors === "isLoading" && (
                      <MenuItem disabled>
                        Loading...
                      </MenuItem>
                    )}

                    {doctors !== "isLoading" && doctors.length === 0 && doctorCity && (
                      <MenuItem disabled>
                        No data
                      </MenuItem>
                    )}
                    {doctors !== "isLoading" && doctors.length === 0 && !doctorCity && (
                      <MenuItem disabled>
                        Select city first
                      </MenuItem>
                    )}
                    {doctors !== "isLoading" && doctors.length !== 0 && doctors
                      .filter(doctors => doctors.active)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(doctor => (
                        <MenuItem key={doctor.id} value={doctor.id}>
                          {doctor.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {formErrors.doctorId && <div>{formErrors.doctorId}</div>}
              </div>
            </div>
            <div>
            <HrComponent />
            </div>
          </div>
          <div className="row" style={{ gap: "16px" }}>
            <div className="col-12 mt-2">
              <h5 style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                textAlign: "left",
                color: "#000000"
              }}>
                Practice Location
              </h5>
            </div>
            <div className="col-12 d-flex" style={{ gap: "8px" }}>
              
              <div className="col-6 pl-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City *</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={locationCity}
                    label="City *"
                    onChange={handleLocationCityChange}
                    MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                  >
                    {cities === "isLoading" && (
                      <MenuItem disabled>
                        Loading...
                      </MenuItem>
                    )}

                    {cities !== "isLoading" && cities.length === 0 && (
                      <MenuItem disabled>
                        No data
                      </MenuItem>
                    )}

                    {cities !== "isLoading" && cities.length !== 0 && cities[0].cities
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(city => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>

              <div className="col-6 pl-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Location *</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Location *"
                    value={selectedValues.practiceLocationId}
                    onChange={(event) => handleLocationChange(event.target.value)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                        },
                      },
                    }}
                  >
                    {locations === "isLoading" && (
                      <MenuItem disabled>
                        Loading...
                      </MenuItem>
                    )}

                    {locations !== "isLoading" && locations.length === 0 && locationCity && (
                      <MenuItem disabled>
                        No data
                      </MenuItem>
                    )}
                    {locations !== "isLoading" && locations.length === 0 && !locationCity && (
                      <MenuItem disabled>
                        Select city first
                      </MenuItem>
                    )}
                    {locations !== "isLoading" && locations.length !== 0 && locations[0].practiceLocations
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(location => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {formErrors.practiceLocationId && <div>{formErrors.practiceLocationId}</div>}
              </div>
            </div>
            <div>
            <HrComponent />
            </div>

          </div>
          <div className="row mt-2" style={{ gap: "16px" }}>
            <div className="col-12 d-flex" style={{ gap: "8px" }}>
              <div className="col-6 pl-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Assign to *</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Assign to *"
                    value={isAssignedToMe ? employeeId : selectedValues.assignedTo} 
                    onChange={(event) => {
                    if (!isAssignedToMe) {
                    handleAssignToChange(event.target.value);
                  }
                }}
    MenuProps={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
    disabled={isAssignedToMe} 
                  >
                    {employees === "isLoading" && (
                      <MenuItem disabled>
                        Loading...
                      </MenuItem>
                    )}

                    {employees !== "isLoading" && employees.length === 0 && (
                      <MenuItem disabled>
                        No data
                      </MenuItem>
                    )}
                    {employees !== "isLoading" && employees.length !== 0 && employees
                      .sort((a, b) => {
                        const firstNameComparison = a.firstName.localeCompare(b.firstName);
                        if (firstNameComparison === 0) {
                          return a.lastName.localeCompare(b.lastName);
                        }
                        return firstNameComparison;
                      })
                      .map(employee => (
                        <MenuItem key={employee.id} value={employee.id}>
                          {employee.firstName + " " + employee.lastName}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {formErrors.assignedTo && <div>{formErrors.assignedTo}</div>}
              </div>

             
              <div className="col-6 pl-0">
  <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Priority *</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label="Priority *"
      value={selectedValues.priority}
      onChange={(event) => handlePriorityChange(event.target.value)}
      MenuProps={{ PaperProps: { style: { maxHeight: 150, width: 250 } } }}
    >
      {priorities === "isLoading" && (
        <MenuItem disabled>
          Loading...
        </MenuItem>
      )}

{priorities !== 'isLoading' && Array.isArray(priorities) && prioritiesData?.length === 0&&(
        <MenuItem disabled>
          No data
        </MenuItem>
      )}

{priorities && Array.isArray(prioritiesData)&& prioritiesData !== 'isLoading' && prioritiesData?.length !== 0 && prioritiesData
          .filter((priority) => priority.active)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((priority) => (
            <MenuItem key={priority.id} value={priority.id}>
              {priority.name}
            </MenuItem>
          ))
      }
    </Select>
  </FormControl>
  {formErrors.priority && <div>{formErrors.priority}</div>}
</div>

             
            </div>

            <div className="form-group" style={{ display: "flex", alignItems: "flex-start" }}>
          <input
            name="active"
            style={{ cursor: "pointer", height: "16px", width: "16px" }}
            type="checkbox" 
            checked={isAssignedToMe}
            className="ml-1 mt-1"
            onChange={handleChange}
          />

          <label
            style={{ paddingLeft: "10px", }}
            className="form-control-label"

          >
            Assign to me
          </label>
        </div>

          </div>
          <div className="row" style={{ gap: "24px", marginTop: "24px" }}>
            <div className="col-12 d-flex" style={{ gap: "8px" }}>
              <div className="col-6 pl-0">
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="multiple-limit-tags-products"
                  onChange={handleProductChange}
                  menuprops={{ PaperProps: { style: { maxHeight: 250, width: 250 } } }}
                  options={products === "isLoading"
                    ? [{ name: "Loading...", id: -1 }]
                    : products.length === 0
                      ? [{ name: "No data", id: -1 }]
                      : products
                        .filter(product => product.active)
                        .sort((a, b) => a.name.localeCompare(b.name))
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Products *" />
                  )}

                />
                {formErrors.products && <div>{formErrors.products}</div>}
              </div>
              <div className="col-6 pl-0">
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="multiple-limit-tags-subtasks"
                  onChange={handleSubTasksChange}
                  menuprops={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                  options={subTasks === "isLoading"
                    ? [{ name: "Loading...", id: -1 }]
                    : subTasks !== 'isLoading' && Array.isArray(subTasksData) && subTasksData.length === 0 
                      ? [{ name: "No data", id: -1 }]
                      : subTasks && Array.isArray(subTasksData)&& subTasksData !== 'isLoading' && subTasksData.length !== 0 && subTasksData
                        .filter(subTasks => subTasks.active)
                        .sort((a, b) => a.name.localeCompare(b.name))
                  }
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Sub Tasks *" />
                  )}

                />
                {formErrors.subTasks && <div>{formErrors.subTasks}</div>}
              </div>
            </div>

          </div>
          <div className="row" style={{ gap: "24px", marginTop: "24px" }}>
            <div className="col-12 d-flex" style={{ gap: "8px" }}>
              <div className="col-6 pl-0">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Visit Date *"
                    valueType="date"
                    minDate={dayjs()}
                    value={selectedValues.visitDate && selectedValues.visitDate.length != 0 && dayjs(selectedValues.visitDate)}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
                {formErrors.visitDate && <div>{formErrors.visitDate}</div>}
              </div>
              <div className="col-6 pl-0">

              </div>
            </div>

          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px 16px 16px 16px",
              gap: "8px"
            }}
          >
            <CancelButton
              onClick={handleGoBack}
            />
            <SaveButton
              onClick={addTaskHandle}
              isLoading={addTask === "isLoading"}
              text={"Save"}
            />
          </div>

        </div>
      </div >
    </>
  );
}

export default AddTasks;
