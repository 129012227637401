import React from "react";
import "./TaskTarget.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaskTarget } from "../../actions/userActions/tasks/taskTargetAction";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { getSubOrdinates } from "../../actions/userActions/tasks/taskAction";
import Loader from "../subComponents/Loader";
import { TasksTargetPieChart } from "../subComponents/recharts/TasksTargetPieChart";
import { Tooltip } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, Menu, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const TaskTarget = () => {
  const dispatch = useDispatch();
  const currentDate = moment();
  const [selectedTab, setSelectedTab] = useState("MY TASKS");
  const [showMyTask, setShowMyTask] = useState(true);
  const [filters, setFilters] = useState({
    taskFromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
    taskToDate: moment(currentDate).format("YYYY-MM-DD"),
  });
  const [selected, setSelected] = useState({
    taskFromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
    taskToDate: moment(currentDate).format("YYYY-MM-DD"),
  });
  const [showDetailsForRow, setShowDetailsForRow] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (auth.isLoggedIn === false && !user) {
      return <Redirect to="/" />;
    }
  });

  useEffect(() => {
    if (selectedTab === "MY TASKS") {
      dispatch(getTaskTarget(body)) 
        .then(() => {
          dispatch(getSubOrdinates(id)); 
        });
    } else if (selectedTab === "SubOrdinates") {
      dispatch(getSubOrdinates(id));
    }
    setShowDetailsForRow({});
  }, [selectedTab, dispatch]);
  

  const handleDetails = (empId, i) => {
    const body = {
      startDate: filters.taskFromDate,
      endDate: filters.taskToDate,
      empId: empId,
    };

    setShowDetailsForRow((prevState) => ({
      ...prevState,
      [i]: !prevState[i],
    }));
    if (!showDetailsForRow[i]) {
      dispatch(getTaskTarget(body));
    }
  };
  const id = user.result[0].user.id;

  const MyTasksChange = () => {
    setSelectedTab("MY TASKS");
    setShowMyTask(true);
  };
  const SubOrdinatesChange = () => {
    setSelectedTab("SubOrdinates");
    setShowMyTask(false);
  };
  const dateChange = (start, end) => {
    let startDate = start.format("YYYY-MM-DD");
    let endDate = end.format("YYYY-MM-DD");
    setFilters({
      ...filters,
      ["taskFromDate"]: startDate,
      ["taskToDate"]: endDate,
    });
  };

  const tasksTarget = useSelector((state) => state.tasks_target_reducer);
  const auth = useSelector((state) => state.auth);
  const subOrdinate = useSelector((state) => state.tasks_reducer.subOrdinates);

  const body = {
    startDate: filters.taskFromDate,
    endDate: filters.taskToDate,
    empId: user.result[0].user.id,
  };

  useEffect(() => {
    dispatch(getTaskTarget(body))
      .then(() => {
        dispatch(getSubOrdinates(id));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [filters]);

  const data = tasksTarget.tasksTarget && tasksTarget.tasksTarget !== "isLoading" && tasksTarget.tasksTarget !== undefined && tasksTarget.tasksTarget && tasksTarget.tasksTarget.filter(item => item.key !== 'Total').map(item => {
    let fill;
    switch (item.key) {
      case 'Created':
        fill = '#87A2C7';
        break;
      case 'In Review':
        fill = '#E1A900';
        break;
      case 'Approved':
        fill = '#2ECC71';
        break;
      case 'Rejected':
        fill = '#E74C3C';
        break;
      case 'In Progress':
        fill = '#5858D1';
        break;
      case 'On Hold':
        fill = '#8381FF';
        break;
      case 'Cancelled':
        fill = '#E71600';
        break;
      case 'Completed(Doctor-Unavailable)':
        fill = '#A9A9A9';
        break;
      case 'Completed(Visit-Complete)':
        fill = '#07A74A';
        break;
      case 'Completed(Refused-To-Meet)':
        fill = '#A9A9A9';
        break;
      case 'Submitted':
        fill = '#42526E';
        break;
      case 'Rescheduled':
        fill = '#9B59B6';
        break;
      default:
        fill = '#CCCCCC';
        break;
    }
    return { key: item.key, value: item.value, fill };
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [customDate, setCustomDate] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedButton, setSelectedButton] = useState("last 7 days");

  const handleToggleSelection = (event, newSelection) => {
    if (newSelection === "last 7 days") {
      setCustomDate(false);
      const fromDate = moment(currentDate).subtract(7, "days").format("YYYY-MM-DD");
      const toDate = moment(currentDate).format("YYYY-MM-DD");
      setSelected(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
    } else if (newSelection === "today") {
      setCustomDate(false);
      const currentDate = moment().toDate();
      setSelected(prevValues => ({ ...prevValues, taskFromDate: currentDate, taskToDate: currentDate }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: currentDate, taskToDate: currentDate }));
    } else if (newSelection === "yesterday") {
      setCustomDate(false);
      const yesterdayDate = moment().subtract(1, "days").toDate();
      setSelected(prevValues => ({ ...prevValues, taskFromDate: yesterdayDate, taskToDate: yesterdayDate }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: yesterdayDate, taskToDate: yesterdayDate }));
    } else if (newSelection === "last 30 days") {
      setCustomDate(false);
      const fromDate = moment().subtract(29, "days").toDate();
      const toDate = moment().toDate();
      setSelected(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
    } else if (newSelection === "this month") {
      setCustomDate(false);
      const fromDate = moment().startOf("month").toDate();
      const toDate = moment().endOf("month").toDate();
      setSelected(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
    } else if (newSelection === "last month") {
      setCustomDate(false);
      const fromDate = moment().subtract(1, "month").startOf("month").toDate();
      const toDate = moment().subtract(1, "month").endOf("month").toDate();
      setSelected(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
    } else if (newSelection === "last year") {
      setCustomDate(false);
      const fromDate = moment().subtract(1, "year").startOf("year").toDate();
      const toDate = moment().subtract(1, "year").endOf("year").toDate();
      setSelected(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: fromDate, taskToDate: toDate }));
    } else if (newSelection === "custom") {
      setCustomDate(true);
      setSelected(prevValues => ({ ...prevValues, taskFromDate: "", taskToDate: "" }));
      setFilters(prevValues => ({ ...prevValues, taskFromDate: "", taskToDate: "" }));
    }

    setSelectedButton(newSelection);
  };
  const handleFromDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelected(prevValues => ({
      ...prevValues,
      taskFromDate: formattedDate,
    }));
    setFilters(prevValues => ({
      ...prevValues,
      taskFromDate: formattedDate,
    }));
  };
  const handleToDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelected(prevValues => ({
      ...prevValues,
      taskToDate: formattedDate,
    }));
    setFilters(prevValues => ({
      ...prevValues,
      taskToDate: formattedDate,
    }));
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-9">
            <h2 className="">Task Targets</h2>
            {/* <p>
              You can see your task targets as well as your team members
            </p>{" "} */}
            <br />
            <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
              <Tab label="MY TASKS" value="MY TASKS" onClick={MyTasksChange} />
              <Tab label="Sub Ordinates" value="SubOrdinates" onClick={SubOrdinatesChange} />
            </Tabs>
          </div>

          <div className="col-md-3 d-flex justify-content-end">
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              startIcon={<FilterAltOutlinedIcon />}
              sx={{
                color: "#505157",
                borderColor: "#D9D9D9",
                border: "1px solid",
                background: "#FFFFFF",
                '& .MuiButton-startIcon': {
                  color: "#505157",
                },
                '&:hover': {
                  color: "#2F54EB",
                  background: "#FFFFFF",
                  borderColor: "#2F54EB",
                  border: "1px solid",
                  '& .MuiButton-startIcon': {
                    color: "#2F54EB",
                  }
                },
                height: "40px",
                minWidth: "114px",
                boxShadow: "2px #00000004",
                borderRadius: "2px"

              }}
            >
              Filter
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  width: "989px",
                  top: "-47614px",
                  left: "-4486px",
                  borderRadius: "4px",
                  background: "#FFFFFF",
                  boxShadow: "0px 11px 15px -7px #00000033",
                },
              }}
            >
              <div className="row" style={{
                padding: "16px 24px 16px 24px",
                justify: "space-between",
                display: "flex",
                gap: "24px"
              }}>
                <div className="col-12 d-flex">
                  <h6 style={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "32px",
                    textAlign: "left",
                    color: "#000000DE"
                  }}>
                    Filters
                  </h6>
                  <Tooltip title="Close Filter" arrow>
                    <CloseIcon style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleClose} />
                  </Tooltip>
                </div>
              </div>
              <div className="row" style={{
                gap: "24px", borderBottom: "1px solid #0000001F",
                padding: "24px 16px 24px 16px",
                alignItems: "center"
              }}>
                <div className="row" style={{ gap: "16px" }}>
                  <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "48px" }}>
                    <ToggleButtonGroup
                      exclusive
                      value={selectedButton}
                      onChange={handleToggleSelection}
                      aria-label="button group"
                    >
                      <ToggleButton
                        value="today"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "today" ? "#2F54EB" : "inherit",
                          color: selectedButton === "today" ? "#fff" : "inherit"
                        }}
                      >
                        Today
                      </ToggleButton>
                      <ToggleButton
                        value="yesterday"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "yesterday" ? "#2F54EB" : "inherit",
                          color: selectedButton === "yesterday" ? "#fff" : "inherit"
                        }}
                      >
                        Yesterday
                      </ToggleButton>
                      <ToggleButton
                        value="last 7 days"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "last 7 days" ? "#2F54EB" : "inherit",
                          color: selectedButton === "last 7 days" ? "#fff" : "inherit"
                        }}
                      >
                        Last 7 days
                      </ToggleButton>
                      <ToggleButton
                        value="last 30 days"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "last 30 days" ? "#2F54EB" : "inherit",
                          color: selectedButton === "last 30 days" ? "#fff" : "inherit"
                        }}
                      >
                        Last 30 days
                      </ToggleButton>
                      <ToggleButton
                        value="this month"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "this month" ? "#2F54EB" : "inherit",
                          color: selectedButton === "this month" ? "#fff" : "inherit"
                        }}
                      >
                        This Month
                      </ToggleButton>
                      <ToggleButton
                        value="last month"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "last month" ? "#2F54EB" : "inherit",
                          color: selectedButton === "last month" ? "#fff" : "inherit"
                        }}
                      >
                        Last Month
                      </ToggleButton>
                      <ToggleButton
                        value="last year"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "last year" ? "#2F54EB" : "inherit",
                          color: selectedButton === "last year" ? "#fff" : "inherit"
                        }}
                      >
                        Last Year
                      </ToggleButton>
                      <ToggleButton
                        value="custom"
                        style={{
                          width: "114px",
                          height: "32px",
                          padding: "4px 8px 4px 8px",
                          gap: "8px",
                          borderRadius: "4px",
                          textTransform: "none",
                          backgroundColor: selectedButton === "custom" ? "#2F54EB" : "inherit",
                          color: selectedButton === "custom" ? "#fff" : "inherit"
                        }}
                      >
                        Custom
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div className="col-12 d-flex" style={{
                    height: "56px",
                  }}>
                    <div className="col-6">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From"
                          valueType="date"
                          value={selected.taskFromDate && selected.taskFromDate.length != 0 ? dayjs(selected.taskFromDate) : null}
                          onChange={handleFromDateChange}
                          disabled={!customDate}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="col-6">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To"
                          valueType="date"
                          value={selected.taskToDate && selected.taskToDate.length != 0 ? dayjs(selected.taskToDate) : null}
                          onChange={handleToDateChange}
                          disabled={!customDate}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          sx={{ width: "100%" }}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </Menu>
          </div>
        </div>

        {showMyTask && selectedTab === "MY TASKS" && (
          <>
            {tasksTarget.tasksTarget === "isLoading" &&
              tasksTarget !== undefined && (
                <div style={{ backgroundColor: "#fff !important" }}>
                  <Loader colored={true} />
                </div>
              )}
            {tasksTarget.tasksTarget !== "isLoading" &&
              tasksTarget !== undefined && tasksTarget.tasksTarget.length !== 0 && (
                <div>
                  <div className="row mt-2">
                    <div className="col-12 all_boxes">
                      <div className="first_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[0].value}
                        </p>
                        <p className="key">
                          Total
                        </p>
                      </div>
                      <div className="second_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[1].value}
                        </p>
                        <p className="key">
                          Created
                        </p>
                      </div>
                      <div className="third_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[2].value}
                        </p>
                        <p className="key">
                          In-Review
                        </p>
                      </div>

                      <div className="four_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[3].value}
                        </p>
                        <p className="key">
                          Approved
                        </p>
                      </div>
                      <div className="five_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[5].value}
                        </p>
                        <p className="key">
                          In-Progress
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 all_boxes">
                      <div className="six_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[4].value}
                        </p>
                        <p className="key">
                          Rejected
                        </p>
                      </div>
                      <div className="seven_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[7].value}
                        </p>
                        <p className="key">
                          Cancelled
                        </p>
                      </div>
                      <div className="eight_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[6].value}
                        </p>
                        <p className="key">
                          On-Hold
                        </p>
                      </div>
                      <div className="nine_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[12].value}
                        </p>
                        <p className="key">
                          Re-Scheduled
                        </p>
                      </div>
                      <div className="ten_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[8].value}
                        </p>
                        <p className="key">
                          Completed <br />(Doctor-Unavailabe)
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 all_boxes">
                      <div className="eleven_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[10].value}
                        </p>
                        <p className="key">
                          Completed <br />(Refused-To-Meet)
                        </p>
                      </div>
                      <div className="tweleve_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[9].value}
                        </p>
                        <p className="key">
                          Completed <br />(Visit-Complete)
                        </p>
                      </div>
                      <div className="thirteen_box">
                        <p className="value">
                          {tasksTarget.tasksTarget[11].value}
                        </p>
                        <p className="key">
                          Submitted
                        </p>
                      </div>

                      <div className="pie-chart">
                        <TasksTargetPieChart
                          data={data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </>
        )}

        {!showMyTask && selectedTab === "SubOrdinates" && (
          <div>
            <div className="row">
              <table className="table table-striped mt-4">
                <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                  <tr>
                    <th className="task-table-header">
                      Id
                    </th>
                    <th className="task-table-header">
                      Name
                    </th>
                    <th className="task-table-header">
                      Designation
                    </th>
                    <th scope="col" className="task-table-header">
                      Reports To
                    </th>
                    <th className="task-table-header">
                      Status
                    </th>

                    <th className="task-table-header">
                      View Details
                    </th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {subOrdinate && subOrdinate.length === 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={6}>No data found</td>
                    </tr>
                  )}
                  {subOrdinate === "isLoading" &&
                    subOrdinate !== undefined && (
                      <tr style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}>
                        <td
                          colSpan={6}
                        >
                          <Loader colored={true} />
                        </td>
                      </tr>
                    )}
                  {subOrdinate !== "isLoading" &&
                    subOrdinate &&
                    subOrdinate.length !== 0 &&
                    subOrdinate.map((row, i) => (
                      <React.Fragment key={i}>
                        <tr style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}>
                          <td><p className='mt-3'>{row.id}</p></td>
                          <td>
                            <div className="avatar-wrapper">
                              <div className='avatar-set'>
                                <p>
                                  {row.firstName.charAt(0)}
                                  {row.lastName.charAt(0)}
                                </p>
                              </div>
                              {row.firstName + " "}{row.lastName}
                            </div></td>
                          <td><p className='mt-3'>{row.designationName}</p></td>
                          <td><p className='mt-3'>{row.reportToName}</p></td>
                          <td>
                            <p className='mt-3'>
                              <span
                                style={{
                                  color: row.employmentStatusName !== "Resigned" ? "var(--primary-main, #2196F3)" : "#D32F2F",
                                  borderRadius: "100px",
                                  border: `1px solid ${row.employmentStatusName !== "Resigned" ? "var(--primary-main, #2196F3)" : "#D32F2F"}`,
                                  padding: "3px 10px",
                                }}
                              >
                                {row.employmentStatusName !== "Resigned" ? "Active" : "Resigned"}
                              </span>
                            </p>
                          </td>
                          <td>
                            <Tooltip title="View Detail" arrow>
                              <button className="mt-3"
                                style={{ background: "none" }}
                                onClick={() => {
                                  const empId = row.id;
                                  handleDetails(empId, i);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="black"
                                  fillOpacity="0.56"
                                >
                                  <path d="M16.59 8.29688L12 12.8769L7.41 8.29688L6 9.70687L12 15.7069L18 9.70687L16.59 8.29688Z" />
                                </svg>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>

                        {showDetailsForRow[i] && (
                          <>
                            {tasksTarget.tasksTarget === "isLoading" &&
                              tasksTarget !== undefined && (
                                <tr style={{
                                  backgroundColor: "transparent",
                                  "--x-table-accent-bg": "transparent",
                                }}>
                                  <td
                                    colSpan={6}
                                  >
                                    <Loader colored={true} />
                                  </td>
                                </tr>
                              )}
                            <tr style={{
                              backgroundColor: "transparent",
                              "--x-table-accent-bg": "transparent",
                            }}>
                              <td colSpan={6} style={{ padding: 0 }}>
                                {tasksTarget.tasksTarget !== "isLoading" &&
                                  tasksTarget !== undefined && (
                                    <>
                                      <div >
                                        <div className="all_boxes">
                                          <div className="first_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[0].value}
                                            </p>
                                            <p className="key">
                                              Total
                                            </p>
                                          </div>
                                          <div className="second_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[1].value}
                                            </p>
                                            <p className="key">
                                              Created
                                            </p>
                                          </div>
                                          <div className="third_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[2].value}
                                            </p>
                                            <p className="key">
                                              In-Review
                                            </p>
                                          </div>

                                          <div className="four_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[3].value}
                                            </p>
                                            <p className="key">
                                              Approved
                                            </p>
                                          </div>
                                          <div className="five_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[5].value}
                                            </p>
                                            <p className="key">
                                              In-Progress
                                            </p>
                                          </div>
                                        </div>

                                        <div className="all_boxes">
                                          <div className="six_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[4].value}
                                            </p>
                                            <p className="key">
                                              Rejected
                                            </p>
                                          </div>
                                          <div className="seven_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[7].value}
                                            </p>
                                            <p className="key">
                                              Cancelled
                                            </p>
                                          </div>
                                          <div className="eight_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[6].value}
                                            </p>
                                            <p className="key">
                                              On-Hold
                                            </p>
                                          </div>
                                          <div className="nine_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[12].value}
                                            </p>
                                            <p className="key">
                                              Re-Scheduled
                                            </p>
                                          </div>
                                          <div className="ten_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[8].value}
                                            </p>
                                            <p className="key">
                                              Completed <br />(Doctor-Unavailabe)
                                            </p>
                                          </div>
                                        </div>

                                        <div className="all_boxes">
                                          <div className="eleven_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[10].value}
                                            </p>
                                            <p className="key">
                                              Completed <br />(Refused-To-Meet)
                                            </p>
                                          </div>
                                          <div className="tweleve_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[9].value}
                                            </p>
                                            <p className="key">
                                              Completed <br />(Visit-Complete)
                                            </p>
                                          </div>
                                          <div className="thirteen_box">
                                            <p className="value">
                                              {tasksTarget.tasksTarget[11].value}
                                            </p>
                                            <p className="key">
                                              Submitted
                                            </p>
                                          </div>

                                          <div className="pie-chart">
                                            <TasksTargetPieChart
                                              data={data}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </td>
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TaskTarget;