import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getCities } from '../../actions/userActions/cityAction'
import Loader from '../subComponents/Loader'
import Pagination from '../paginatioin/pagination'
import AddButton from '../subComponents/buttons/AddButton'
import { IconButton,Tooltip } from "@mui/material";
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function Cities() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '0',
  })

  // City Body
  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 10,
  }
  const [selected, setSelected] = useState({
    textSearch: '',
    countryId: '1',
  })
  useEffect(() => {
    dispatch(getCities(cityBody))
  }, [page, cityFilters])

  const cities = useSelector((state) => state.cities_reducer.cities)
  const [query, setQuery] = useState('')
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  const getPaginationNo = (pageNum) => {
    const object = []
    if (cities !== 'isLoading' && cities.length !== 0) {
      for (let i = 0; i < cities[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setCityFilters({ ...cityFilters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setCityFilters({ ...cityFilters, ['textSearch']: e.target.value })
    }
  }
  const handleAddCityClick = () => {
    history.push('/admin/AddCities');
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Cities</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <ServerSideSearch
              placeholder="Search City"
              onChange={handleSearch}
              value={selected.textSearch}
            />
            {permissions.includes('CanAddCity') && (
              <AddButton onClick={handleAddCityClick} text="+ Add City" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Short Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {cities === 'isLoading' && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={3}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {cities && cities.length == 0 && (
                  <tr className="text-center" style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td colSpan={3}>No data found</td>
                  </tr>
                )}

                {cities !== 'isLoading' &&
                  cities.length !== 0 &&
                  cities[0].cities
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td style={{verticalAlign:"middle"}}>{row.shortName}</td>

                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes("CanUpdateCity") && (
                            <Tooltip title="Edit City" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: '/admin/UpdateCities',
                                  city: { row },
                                  id: row.id,
                                }}
                              >
                             <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {cities !== 'isLoading' && cities.length !== 0 && (
              <div className="task-pagination-wrapper">
                <Pagination
                  total={
                    cities[0].page != undefined &&
                    cities[0].page.totalPages * cities[0].page.pageSize
                  }
                  current={cities[0].page != undefined && cities[0].page.pageNumber}
                  pageSize={cities[0].page != undefined && cities[0].page.pageSize}
                  onChange={(current) => setPage(current)}
                  showLessItems
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Cities
