import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDoctorSpecializations } from "../../actions/userActions/doctorSpecializationAction";
import { Link, useHistory } from "react-router-dom";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";


function DoctorSpecializations() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [expanded, setExpanded] = useState({});
  const [currentPage, setCurrentPage] = useState(1)
  const [previousQuery, setPreviousQuery] = useState("");
  const [query, setQuery] = useState("");


  const toggleExpansion = (id) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    dispatch(getDoctorSpecializations());
  }, [dispatch]);

  const specializations = useSelector(
    (state) => state.doctor_specialization_reducer.specializations
  );
  const specializationsData = specializations && specializations.result;
  const totalPages = specializations?.totalPages || 0;
  
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const handleAddDoctorSpecializationClick = () => {
    history.push('/admin/AddDoctorSpecializations');
  };
  useEffect(() => {
    const pageNumber = currentPage;
  
      if (query !== previousQuery) {
        setCurrentPage(1);
      }
      dispatch(getDoctorSpecializations(pageNumber, SIZE_OF_PAGE, query));
      setPreviousQuery(query);
  
    }, [currentPage, query]);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  const handleSearchChange = (query) => {
    setQuery(query);
    setCurrentPage(1);
    history.push(`?page=1&search=${query}`);
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Doctor Specializations</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search
              setText={handleSearchChange}
              text={query}
            />
            {permissions.includes("CanAddDoctorSpecialization") && (
              <AddButton onClick={handleAddDoctorSpecializationClick} text="+ Add Specialization" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {specializations === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td colSpan={3}>
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {specializations !== 'isLoading' && Array.isArray(specializationsData) && specializationsData.length === 0 &&(
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={3}>No data found</td>
                    </tr>
                  )}
                {specializations && Array.isArray(specializationsData)&& specializationsData !== 'isLoading' && specializationsData.length !== 0 && specializationsData
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td
                          style={{
                            whiteSpace: "pre-line",
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              maxHeight:
                                expanded[row.id] || row.description.length <= 155
                                  ? "none"
                                  : "3.4em",
                              overflow: "hidden",
                              position: "relative",
                              width: "71%",
                            }}
                          >
                            {row.description}
                            {row.description.length > 155 && (
                              <span
                                className="toggle_expand"
                                onClick={() => toggleExpansion(row.id)}
                              >
                                {expanded[row.id] ? '...' : '...'}
                              </span>
                            )}
                          </div>
                        </td>
                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes('CanUpdateDoctorSpecialization') && (
                            <Tooltip title="Edit Specialization" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: "/admin/UpdateDoctorSpecializations",
                                  doctorSpecialization: { row },
                                  id: row.id,
                                }}
                              >
                               <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPage}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default DoctorSpecializations;
