import React from 'react'
import './Login.css'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../../actions/auth/auth'
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../subComponents/Loader';
import logo from "../../../assets/images/Logo.svg";
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import login_image from "../../../assets/images/login_image.svg";
import CopyrightIcon from '@mui/icons-material/Copyright';
import mobile_image_login from "../../../assets/images/mobile_image_login.svg";
import SidebarIcons from '../../nav/SidebarIcons';
import { DOWNLOAD_APP_URL } from '../../../Constatns';


function Login() {
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const download_app = DOWNLOAD_APP_URL;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));
  const [randomSubtitle, setRandomSubtitle] = useState('');
  const subtitles = [
    "Analyzing previous trends using AI and ensuring that you always make the right decision and achieve your goals with confidence and efficiency.",
    "Set Priority Levels: Prioritize your tasks by setting their importance. Use color-coded tags to easily identify high-priority items.",
    "Custom Reminders: Never miss a deadline by setting custom reminders for your tasks. You can choose to be notified minutes, hours, or days in advance.",
    "Collaborate Seamlessly: Assign tasks to team members and track their progress in real-time. Enhance collaboration and ensure everyone stays on the same page",
    "Daily Summaries: Start your day with a quick overview of your tasks and priorities with our daily summary feature.",
    "Track Progress: Use the progress tracker to monitor the completion of tasks and stay motivated by seeing your progress."
  ];

  useEffect(() => {
    // Set a random subtitle on component mount
    const randomIndex = Math.floor(Math.random() * subtitles.length);
    setRandomSubtitle(subtitles[randomIndex]);
  }, []);
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        loginHandle();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };

  }, [formValues])


  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(login(formValues.email.trim(), formValues.password));
    }
  }, [formErrors])

  const loginHandle = async () => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    { user && !user.status && localStorage.clear() }
  }
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!values.email) {
      errors.email = "Email is required!"
    } else if (!emailRegex.test(values.email.trim())) {
      errors.email = "Invalid email!";
    }
    if (!values.password) {
      errors.password = "Password is required!"
    }
    return errors;
  }
  if (user && user.status) {
    return <Redirect to="/admin" />
  }

  else return (
    <>
      {user && !user.status && <ToastContainer />}
      <div className='container-fluid p-0'>
        <div className='row'>
          <div className='col-6 d-flex flex-column justify-content-between' style={{ minHeight: '100vh' }} >
            <div className="form-wrapper">
              <div className="form-container">
                <div className='logo'>
                  <img src={logo} alt="Logo" />
                </div>
                <div className='login-form'>
                  <div className='login-fields'>
                    <div className='fields-container'>
                      <div className='email'>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="outlined-email">Email</InputLabel>
                          <OutlinedInput
                            name="email"
                            id="email"
                            type="text"
                            value={formValues.email}
                            onChange={handleChange}
                            startAdornment={(
                              <InputAdornment position="start">
                                <EmailOutlinedIcon />
                              </InputAdornment>
                            )}
                            label="Email"
                          />
                          <p style={{ color: 'red' }}>{formErrors.email}</p>
                        </FormControl>
                      </div>
                      <div className='password'>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            name="password"
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formValues.password}
                            onChange={handleChange}
                            startAdornment={(
                              <InputAdornment position="start">
                                <LockOutlinedIcon />
                              </InputAdornment>
                            )}
                            endAdornment={
                              <InputAdornment position="end" sx={{ marginRight: 0 }}>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  sx={{
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                    },
                                    padding: '5px',
                                  }}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                          <p style={{ color: 'red' }}>{formErrors.password}</p>
                        </FormControl>
                      </div>
                      {/* <div className='check-box'>
                        <FormControlLabel control={<Checkbox size="small" />} label="Keep logged in" />
                      </div> */}
                    </div>
                    <div className='login-button'>
                      <Button
                        onSubmit={loginHandle}
                        onClick={loginHandle}
                        disabled={auth.isLogging}
                        style={{
                          position: "relative",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: " 4px 14px 4px 14px",
                          gap: "8px",
                          borderRadius: "6px",
                          border: "1px solid #D9D9D9",
                          background: "#002D72",
                          color: "#FFFFFF",
                          boxShadow: "2px #0000000B",
                          textTransform: "none",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease"
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#004BB4"}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#002D72"}
                        fullWidth
                      >
                        {auth.isLogging ? (
                          <div style={{ display: "inline-block", marginTop: "8px" }}>
                            <Loader />
                          </div>
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </div>
                    <p className="text-center mt-2">
                      <a href="/forgotPassword">
                        Forgot Password?
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-1">
              <CopyrightIcon />
              <p>Copyright Field Force 2024</p>
            </div>
          </div>
          <div className='col-6' style={{ background: `url(${login_image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='main-right'>
              <div className='top-right'>
                <div className='col'>
                  <div className='title'>
                    <p>
                      Empower Your Productivity
                    </p>
                    <div className='sub-title mt-2'>
                      <span>
                        Effortlessly manage tasks, enhance productivity, and achieve your goals with FieldForce-AI.
                      </span>
                    </div>
                    <Button
                     onClick={() => window.location.href = download_app}
                      style={{
                        position: "relative",
                        marginTop: "24px",
                        width: "255px",
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: " 4px 14px 4px 14px",
                        gap: "8px",
                        borderRadius: "6px",
                        border: "1px solid #D9D9D9",
                        background: "#002D72",
                        color: "#FFFFFF",
                        boxShadow: "2px #0000000B",
                        textTransform: "none",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease"
                      }}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#004BB4"}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#002D72"}
                      fullWidth
                    >
                      <img
                        src={SidebarIcons.download_icon}
                        width={25}
                        height={25}
                        alt="Download "
                        style={{ marginRight: "8px" }}
                      />
                      Download Android APP
                    </Button>
                  </div>
                </div>
                <div className='col mobile-image'>
                  <img src={mobile_image_login} />
                </div>
              </div>
              <div className='bottom-right'>
                <div className='title-bottom'>
                  <p>Introducing new features</p>
                </div>
                <div className='sub-title-bottom'>
                  <span id="randomText">{randomSubtitle}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login
